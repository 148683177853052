import {Action} from '@ngrx/store';

export const REGISTRATION_START = '[Auth] Signup start';
export const LOGIN_START = '[Auth] Login start';
export const RESET_PASSWORD_EMAIL_START = '[Auth] Reset email password start';
export const RESET_PASSWORD_EMAIL_SUCCESS = '[Auth] Reset email password success';
export const CHECK_TOKEN_START = '[Auth] Check token start';
export const CHECK_TOKEN_SUCCESS = '[Auth] Check token success';
export const CHECK_TOKEN_FAIL = '[Auth] Check token fail';
export const RESET_PASSWORD_START = '[Auth] Reset password start';
export const RESET_PASSWORD_SUCCESS = '[Auth] Reset password success';
export const AUTHENTICATE_SUCCESS = '[Auth] AuthenticateSuccess';
export const AUTHENTICATE_FAIL = '[Auth] AuthenticateSuccess fail';
export const AUTO_LOGIN = '[Auth] Auto login';
export const LOGOUT = '[Auth] Logout';
export const CLEAR_ERROR = '[Auth] Clear Error';
export const CLEAR_SUCCESS = '[Auth] Clear Success';

export class RegistrationStart implements Action {
  readonly type = REGISTRATION_START;
  constructor(
    public payload: {
        email: string,
        password: string,
        passwordConfirmation: string,
        firstName: string,
        lastName: string
      }
    ) {}
}

export class LoginStart implements Action {
  readonly type = LOGIN_START;
  constructor(public payload: {email: string, password: string}) {}
}

export class ResetPasswordEmailStart implements Action {
  readonly  type = RESET_PASSWORD_EMAIL_START;
  constructor(public payload: {email: string}) {}
}
export class ResetPasswordEmailSuccess implements Action {
  readonly  type = RESET_PASSWORD_EMAIL_SUCCESS;
  constructor(public payload: {message: string}) {}
}

export class CheckTokenStart implements Action {
  readonly  type = CHECK_TOKEN_START;
  constructor(public payload: {email: string, token: string}) {}
}
export class CheckTokenSuccess implements Action {
  readonly  type = CHECK_TOKEN_SUCCESS;
  constructor(public payload: {message: string, isTokenValid: boolean}) {}
}
export class CheckTokenFail implements Action {
  readonly  type = CHECK_TOKEN_FAIL;
  constructor(public payload: {message: string, isTokenValid: boolean}) {}
}

export class ResetPasswordStart implements Action {
  readonly  type = RESET_PASSWORD_START;
  constructor(public payload: {email: string, password: string, token: string}) {}
}
export class ResetPasswordSuccess implements Action {
  readonly  type = RESET_PASSWORD_SUCCESS;
  constructor(public payload: {message: string}) {}
}

export class AuthenticateSuccess implements Action {
  readonly type = AUTHENTICATE_SUCCESS;

  constructor(public payload: {
    email: string;
    token: string;
    expirationDate: Date;
  }) {}
}

export class AuthenticateFail implements Action {
  readonly type = AUTHENTICATE_FAIL;
  constructor(public payload: {errorMessage: string, errors}) {}
}
export class ClearError implements  Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ClearSuccess implements  Action {
  readonly type = CLEAR_SUCCESS;
  constructor() {}
}

export class AutoLogin implements Action {
  readonly  type = AUTO_LOGIN;
}


export class Logout implements Action {
  readonly type = LOGOUT;
}

export type AuthenticationActions =
  | RegistrationStart
  | LoginStart
  | ResetPasswordEmailStart
  | ResetPasswordEmailSuccess
  | CheckTokenStart
  | CheckTokenSuccess
  | CheckTokenFail
  | ResetPasswordStart
  | ResetPasswordSuccess
  | AuthenticateSuccess
  | AuthenticateFail
  | ClearError
  | ClearSuccess
  | AutoLogin
  | Logout;
