import * as ReportActions from '../store/report.actions';
import {ReportModel} from '../../../shared/models/report/report.model';
import {BasicState} from '../../../shared/models/basic-state';

export interface State extends BasicState {
  reports: ReportModel[];
}

const initialState: State = {
  reports: [],
  loading: null,
  success: null,
  error: null,
  hasLoaded: false
}

export function reportReducer(state: State = initialState, action: ReportActions.ReportActions) {
  switch (action.type) {
    case ReportActions.GET_START:
    case ReportActions.CREATE_START:
    case ReportActions.UPDATE_START:
    case ReportActions.DELETE_START:
      return {
        ...state,
        loading: true,
        success: null,
        error: null
      }
    case ReportActions.GET_SUCCESS:
      return {
        ...state,
        reports: [...action.payload.reports],
        loading: false,
        hasLoaded: true
      }
    case ReportActions.CREATE_SUCCESS:
      return {
        ...state,
        reports: [...state.reports, action.payload.report],
        loading: false,
        success: action.payload.message
      }
    case ReportActions.UPDATE_SUCCESS:
      const updatedReport: ReportModel = {
        ...state.reports.find(r => r.id === action.payload.report.id),
        ...action.payload.report
      };
      const updateIndex = state.reports.findIndex(r => r.id === action.payload.report.id);
      const updatedReports = [...state.reports];
      updatedReports[updateIndex] = updatedReport;
      return {
        ...state,
        reports: updatedReports,
        loading: false,
        success: action.payload.message
      }
    case ReportActions.DELETE_SUCCESS:
      const deleteIndex = state.reports.findIndex(r => r.id === action.payload.id);
      state.reports.splice(deleteIndex, 1);
      return {
        ...state,
        reports: [...state.reports],
        loading: false,
        success: action.payload.message
      }
    case ReportActions.REPORT_FAIL:
      return {
        ...state,
        loading: false,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
      }
    case ReportActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case ReportActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      }
    default:
      return state;
  }
}
