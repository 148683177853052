import * as CurrencyActions from './currency.actions';
import {BasicState} from '../../../shared/models/basic-state';
import {CurrencyModel} from '../../../shared/models/currency/currency.model';

export interface State extends BasicState {
  currencies: CurrencyModel[];
}

const initialState: State = {
  currencies: [],
  loading: false,
  hasLoaded: false,
  error: null,
  success: null
}

export function currencyReducer(state: State = initialState, action: CurrencyActions.CurrencyActions) {
  switch (action.type) {
    case CurrencyActions.GET_START:
      return {
        ...state,
        loading: true,
        error: null,
        success: null
      }
    case CurrencyActions.GET_SUCCESS:
      return {
        ...state,
        currencies: [...action.payload.currencies],
        loading: false
      }
    case CurrencyActions.CURRENCY_FAIL:
      return {
        ...state,
        loading: false,
        hasLoaded: true,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
      }
    case CurrencyActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    default:
      return state;
  }
}
