import { Component, OnInit } from '@angular/core';
import {NbMenuItem} from '@nebular/theme';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  items: NbMenuItem[] = [
    {
      title: 'Home',
      icon: 'home-outline',
      link: '/home',
      home: true
    },
    {
      title: 'Profile',
      icon: 'person-outline',
      link: '/user/profile',
    },
    {
      title: 'Clients',
      icon: 'people-outline',
      link: '/client/index',
    },
    {
      title: 'Projects',
      icon: 'layers-outline',
      link: '/project/index',
    },
    {
      title: 'Leads',
      icon: 'person-outline',
      link: '/lead/index',
    },
    {
      title: 'Jobs',
      icon: 'list-outline',
      link: '/job/index',
    },
    {
      title: 'Reports',
      icon: 'file-text-outline',
      link: '/report/index',
    },
    {
      title: 'Analysis',
      icon: 'bar-chart-outline',
      link: '/analysis/index',
    },
    {
      title: 'Invoices',
      icon: 'file-outline',
      link: '/invoice/index',
    }
  ];
  constructor() { }

  ngOnInit() {
  }

}
