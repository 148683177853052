import {Action} from '@ngrx/store';
import {CurrencyModel} from '../../../shared/models/currency/currency.model';

export const GET_START = '[Currency] Get currency start';
export const GET_SUCCESS = '[Currency] Get currency success';
export const CURRENCY_FAIL = '[Currency] Currency fail';
export const CLEAR_ERROR = '[Currency] Currency clear error message';

export class GetStart implements Action {
  readonly type = GET_START;
  constructor() {}
}
export class GetSuccess implements Action {
  readonly type = GET_SUCCESS;
  constructor(public payload: {currencies: CurrencyModel[]}) {}
}
export class CurrencyFail {
  readonly type = CURRENCY_FAIL;
  constructor(public payload: {errorMessage: string, errors}) {}
}
export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}

export type CurrencyActions = GetStart | GetSuccess | CurrencyFail | ClearError;
