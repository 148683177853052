import { NgModule } from '@angular/core';
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbDialogRef,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule, NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbUserModule
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {Ng2SmartTableModule} from 'ng2-smart-table';
import {NbMomentDateModule} from '@nebular/moment';

@NgModule({
  declarations: [],
  imports: [
    NbLayoutModule,
    NbIconModule,
    NbEvaIconsModule,
    NbButtonModule,
    NbCardModule,
    NbInputModule,
    NbSpinnerModule,
    NbAlertModule,
    NbUserModule,
    NbListModule,
    Ng2SmartTableModule,
    NbSelectModule,
    NbDatepickerModule.forRoot(),
    NbMomentDateModule,
    NbContextMenuModule,
    NbDialogModule.forRoot(),
    NbRadioModule
  ],
  exports: [
    NbLayoutModule,
    NbIconModule,
    NbEvaIconsModule,
    NbButtonModule,
    NbCardModule,
    NbInputModule,
    NbSpinnerModule,
    NbAlertModule,
    NbUserModule,
    NbListModule,
    Ng2SmartTableModule,
    NbSelectModule,
    NbDatepickerModule,
    NbMomentDateModule,
    NbContextMenuModule,
    NbDialogModule,
    NbRadioModule
  ],
  providers: [
    {provide: NbDialogRef}
  ]
})
export class NebularModule { }
