import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import * as ReportActions from './report.actions';
import {of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ReportSearchResponseModel} from '../../../shared/models/report/report-search-response.model';
import {environment} from '../../../../environments/environment';
import {ReportModel} from '../../../shared/models/report/report.model';
import {DefaultResponseModel} from '../../../shared/models/default-response.model';

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error.message) {
    errorMessage = errorRes.error.message;
  } else if (errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message;
    errors = errorRes.error.errors.errors;
  }
  return of(new ReportActions.ReportFail({errorMessage, errors}));
};

@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router
  ) {}
  @Effect()
  getReports = this.actions$.pipe(
    ofType(ReportActions.GET_START),
    switchMap((reportData: ReportActions.GetStart) => {
      return this.http.get<ReportSearchResponseModel>(
        environment.baseApiUrl + 'dailyReports' + reportData.payload.filter
      ).pipe(
        map(resData => {
          return new ReportActions.GetSuccess({reports: resData.data});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  createReport = this.actions$.pipe(
    ofType(ReportActions.CREATE_START),
    switchMap((reportData: ReportActions.CreateStart) => {
      return this.http.post<DefaultResponseModel>(
        environment.baseApiUrl + 'dailyReports', reportData.payload.report
      ).pipe(
        map(resData => {
          return new ReportActions.CreateSuccess({report: resData.success.data as ReportModel, message: resData.success.message});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  updateReport = this.actions$.pipe(
    ofType(ReportActions.UPDATE_START),
    switchMap((reportData: ReportActions.UpdateStart) => {
      return this.http.post<DefaultResponseModel>(
        environment.baseApiUrl + 'dailyReports/' + reportData.payload.id, reportData.payload.report,
      ).pipe(
        map(resData => {
          return new ReportActions.UpdateSuccess({report: resData.success.data as ReportModel, message: resData.success.data.message});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  deleteReport = this.actions$.pipe(
    ofType(ReportActions.DELETE_START),
    switchMap((reportData: ReportActions.DeleteSuccess) => {
      return this.http.delete<DefaultResponseModel>(
        environment.baseApiUrl + 'dailyReports/' + reportData.payload.id
      ).pipe(
        map(resData => {
          return new ReportActions.DeleteSuccess({id: resData.success.data.id, message: resData.success.message});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect({dispatch: false})
  reportSuccess = this.actions$.pipe(
    ofType(
      ReportActions.CREATE_SUCCESS,
      ReportActions.UPDATE_SUCCESS,
      ReportActions.DELETE_SUCCESS
    ),
    tap(action => {
      this.router.navigate(['report/index']);
    })
  );
}
