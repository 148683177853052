import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from './material.module';
import {NebularModule} from './nebular.module';
import {MinutesToHoursPipe} from '../core/pipes/minutes-to-hours.pipe';
import {CKEditorModule} from 'ckeditor4-angular';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [
    MinutesToHoursPipe,
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NebularModule,
    CKEditorModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NebularModule,
    MinutesToHoursPipe,
    CKEditorModule,
    ConfirmDialogComponent
  ],
  entryComponents: [
    ConfirmDialogComponent
  ]
})
export class SharedModule { }
