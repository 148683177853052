import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ClientSearchResponseModel} from '../../../shared/models/client/client-search-response.model';
import {Router} from '@angular/router';
import * as ClientActions from './client.actions';
import {DefaultResponseModel} from '../../../shared/models/default-response.model';
import {ClientModel} from '../../../shared/models/client/client.model';
import {of} from 'rxjs';
const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error.message) {
    errorMessage = errorRes.error.message;
  } else if (errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message;
    errors = errorRes.error.errors.errors;
  }
  return of(new ClientActions.ClientFail({errorMessage, errors}));
};

@Injectable()
export class ClientEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private http: HttpClient
  ) {}
  @Effect()
  getClient = this.actions$.pipe(
    ofType(ClientActions.GET_START),
    switchMap((clientData: ClientActions.GetStart) => {
      return this.http.get<ClientSearchResponseModel>(
        environment.baseApiUrl + 'clients?filter=' + clientData.payload.filter,
        {}
      ).pipe(
        map(resData => {
          return new ClientActions.GetSuccess({clients: resData.data});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  createClient = this.actions$.pipe(
    ofType(ClientActions.CREATE_START),
    switchMap((clientData: ClientActions.CreateStart) => {
      return this.http.post(
        environment.baseApiUrl + 'clients', clientData.payload.client
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new ClientActions.CreateSuccess({
            client: resData.success.data as ClientModel,
            message: resData.success.message
          });
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  updateClient = this.actions$.pipe(
    ofType(ClientActions.UPDATE_START),
    switchMap((clientData: ClientActions.UpdateStart) => {
      return this.http.post(
        environment.baseApiUrl + 'clients/' + clientData.payload.id, clientData.payload.client
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new ClientActions.UpdateSuccess({
            client: resData.success.data as ClientModel
          });
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  updateLogo = this.actions$.pipe(
    ofType(ClientActions.UPLOAD_LOGO_START),
    switchMap((clientData: ClientActions.UploadLogoStart) => {
      return this.http.post(
        environment.baseApiUrl + 'clients/' + clientData.payload.id + '/logo',
        clientData.payload.logo
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new ClientActions.UploadLogoSuccess({
            client: resData.success.data as ClientModel,
            message: resData.success.message
          });
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  deleteClient = this.actions$.pipe(
    ofType(ClientActions.DELETE_START),
    switchMap((clientData: ClientActions.DeleteStart) => {
      return this.http.delete(
        environment.baseApiUrl + 'clients/' + clientData.payload.id,
        {}
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new ClientActions.DeleteSuccess({message: resData.success.message, id: resData.success.data.id});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect({dispatch: false})
  clientSuccess = this.actions$.pipe(
    ofType(
      ClientActions.CREATE_SUCCESS,
      ClientActions.UPDATE_SUCCESS,
      ClientActions.DELETE_SUCCESS
    ),
    tap(action => {
      this.router.navigate(['/client/index']);
    })
  );
}
