import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {AuthtenticationGuard} from './core/authentication/authtentication.guard';
import {UserResolve} from './core/resolvers/user.resolve';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent, canActivate: [AuthtenticationGuard], resolve: {store: UserResolve}},
  {path: 'auth', loadChildren: './authentication/authentication.module#AuthenticationModule'},
  {path: 'user', loadChildren: './pages/user/user.module#UserModule'},
  {path: 'client', loadChildren: './pages/client/client.module#ClientModule'},
  {path: 'project', loadChildren: './pages/project/project.module#ProjectModule'},
  {path: 'report', loadChildren: './pages/report/report.module#ReportModule'},
  {path: 'job', loadChildren: './pages/job/job.module#JobModule'},
  {path: 'analysis', loadChildren: './pages/analysis/analysis.module#AnalysisModule'},
  {path: 'lead', loadChildren: './pages/lead/lead.module#LeadModule'},
  {path: 'invoice', loadChildren: './pages/invoice/invoice.module#InvoiceModule'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
