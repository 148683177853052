import {BasicState} from '../../../shared/models/basic-state';
import {LeadModel} from '../../../shared/models/lead/lead.model';
import * as LeadActions from './lead.actions';

export interface State extends BasicState {
  leads: LeadModel[];
}

const initialState: State = {
  leads: [],
  loading: false,
  error: null,
  success: null,
  hasLoaded: false
}

export function leadReducer(state: State = initialState, action: LeadActions.LeadActions) {
  switch (action.type) {
    case LeadActions.GET_START:
    case LeadActions.CREATE_START:
    case LeadActions.UPDATE_START:
    case LeadActions.DELETE_START:
      return {
        ...state,
        loading: true,
        error: null,
        success: null
      }
    case LeadActions.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: [...action.payload.leads],
        hasLoaded: true
      }
    case LeadActions.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: [...state.leads, action.payload.lead],
        success: action.payload.message
      }
    case LeadActions.UPDATE_SUCCESS:
      const updatedLead  = {
        ...state.leads.find(lead => lead.id === action.payload.lead.id),
        ...action.payload.lead
      };
      const updatedIndex  = state.leads.findIndex(lead => lead.id === action.payload.lead.id);
      const updatedLeads = [...state.leads];
      updatedLeads[updatedIndex] = updatedLead;
      return {
        ...state,
        loading: false,
        leads: updatedLeads,
        success: action.payload.message
      }
    case LeadActions.DELETE_SUCCESS:
      const deleteIndex = state.leads.findIndex(lead => lead.id === action.payload.id);
      state.leads.splice(deleteIndex, 1);
      return {
        ...state,
        loading: false,
        success: action.payload.message
      }
    case LeadActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      }
    case LeadActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case LeadActions.LEAD_FAIL:
      return {
        ...state,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
        loading: false
      }
    default:
      return state;
  }
}
