import {Action} from '@ngrx/store';
import {LeadModel} from '../../../shared/models/lead/lead.model';


export const GET_START = '[Lead] Get lead start';
export const GET_SUCCESS = '[Lead] Get lead success';
export const CREATE_START = '[Lead] Create lead start';
export const CREATE_SUCCESS = '[Lead] Create lead success';
export const UPDATE_START = '[Lead] Update lead start';
export const UPDATE_SUCCESS = '[Lead] Update lead success';
export const DELETE_START = '[Lead] Delete lead start';
export const DELETE_SUCCESS = '[Lead] Delete lead success';
export const CLEAR_SUCCESS = '[Lead] Clear lead success message';
export const CLEAR_ERROR = '[Lead] Clear lead error message';
export const LEAD_FAIL = '[Lead] Lead fail';

export class GetStart implements Action {
  readonly type = GET_START;
  constructor(public payload: {filter: string}) {}
}
export class GetSuccess implements Action {
  readonly type = GET_SUCCESS;
  constructor(public payload: {leads: LeadModel[]}) {}
}
export class CreateStart implements Action {
  readonly type = CREATE_START;
  constructor(public payload: {lead: LeadModel}) {}
}
export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;
  constructor(public payload: {lead: LeadModel, message: string}) {}
}
export class UpdateStart implements Action {
  readonly type = UPDATE_START;
  constructor(public payload: {lead: LeadModel, id: string}) {}
}
export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;
  constructor(public payload: {lead: LeadModel, message: string}) {}
}
export class DeleteStart implements Action {
  readonly type = DELETE_START;
  constructor(public payload: {id: string}) {}
}
export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;
  constructor(public payload: {id: string, message: string}) {}
}
export class ClearSuccess implements Action {
  readonly type = CLEAR_SUCCESS;
  constructor() {}
}
export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class LeadFail implements Action {
  readonly type = LEAD_FAIL;
  constructor(public payload: {errorMessage: string, errors}) {}
}
export type LeadActions =
  GetStart |
  GetSuccess |
  CreateStart |
  CreateSuccess |
  UpdateStart |
  UpdateSuccess |
  DeleteStart |
  DeleteSuccess |
  ClearError |
  ClearSuccess |
  LeadFail;
