import * as ProjectActions from './project.actions';
import {ProjectModel} from '../../../shared/models/project/project.model';
import {BasicState} from '../../../shared/models/basic-state';

export interface State extends BasicState {
  projects: ProjectModel[];
}

const initialState: State = {
  projects: [],
  error: null,
  success: null,
  loading: false,
  hasLoaded: false
}

export function projectReducer(state = initialState, action: ProjectActions.ProjectActions) {
  switch (action.type) {
    case ProjectActions.GET_START:
    case ProjectActions.CREATE_START:
    case ProjectActions.UPDATE_START:
    case ProjectActions.DELETE_START:
      return {
        ...state,
        loading: true,
        errors: null,
        success: null
      }
    case ProjectActions.GET_SUCCESS:
      return {
        ...state,
        projects: [...action.payload.projects],
        loading: false,
        hasLoaded: true
      }
    case ProjectActions.CREATE_SUCCESS:
      return {
        ...state,
        projects: [...state.projects, action.payload.project],
        loading: false
      }
    case ProjectActions.UPDATE_SUCCESS:
      const updatedProject = {
        ...state.projects.find(project => project.id === action.payload.project.id),
        ...action.payload.project
      };
      const updateIndex = state.projects.findIndex(project => project.id === action.payload.project.id);
      const updatedProjects = [...state.projects];
      updatedProjects[updateIndex] = updatedProject;
      return {
        ...state,
        projects: updatedProjects,
        loading: false
      }
    case ProjectActions.DELETE_SUCCESS:
      const deleteIndex = state.projects.findIndex(project => project.id === action.payload.id);
      state.projects.splice(deleteIndex, 1);
      return {
        ...state,
        projects: [...state.projects],
        success: action.payload.message,
        loading: false
      }
    case ProjectActions.PROJECT_FAIL:
      return{
        ...state,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
        loading: false
      }
    case ProjectActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case ProjectActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      }
    default:
      return state;
  }
}
