import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import * as AnalysisActions from '../store/analysis.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {of} from 'rxjs';
import {ReportModel} from '../../../shared/models/report/report.model';
import {ReportSearchResponseModel} from '../../../shared/models/report/report-search-response.model';

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error.message) {
    errorMessage = errorRes.errors.message;
  } else if (errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message;
    errors = errorRes.error.errors.errors;
  }
  return of(new AnalysisActions.FilterFail({errorMessage, errors}));
};

@Injectable()
export class AnalysisEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient
  ) {}
  @Effect()
  filterStart = this.actions$.pipe(
    ofType(AnalysisActions.FILTER_START),
    switchMap((analysisData: AnalysisActions.FilterStart) => {
      return this.http.get<ReportSearchResponseModel>(
        environment.baseApiUrl + 'dailyReports' + analysisData.payload.filter,
        {}
      ).pipe(
        map(resData => {
          return new AnalysisActions.FilterSuccess({
            reports: resData.data as ReportModel[],
            sumTimeInMinutes: resData.sumTimeInMinutes.sumTimeInMinutes
          });
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
}
