import * as fromAuthentication from '../authentication/store/authentication.reducer';
import * as fromUser from '../pages/user/store/user.reducer';
import * as fromClient from '../pages/client/store/client.reducer';
import * as fromProject from '../pages/project/store/project.reducer';
import * as fromJob from '../pages/job/store/job.reducer';
import * as fromReport from '../pages/report/store/report.reducer';
import * as fromAnalysis from '../pages/analysis/store/analysis.reducer';
import * as fromLead from '../pages/lead/store/lead.reducer';
import * as fromInvoice from '../pages/invoice/store/invoice.reducer';
import * as fromCurrencies from '../pages/currency/store/currency.reducer';

import {ActionReducerMap} from '@ngrx/store';

export interface AppState {
  authentication: fromAuthentication.State;
  user: fromUser.State;
  client: fromClient.State;
  project: fromProject.State;
  job: fromJob.State;
  report: fromReport.State;
  analysis: fromAnalysis.State;
  lead: fromLead.State;
  invoice: fromInvoice.State;
  currency: fromCurrencies.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  authentication: fromAuthentication.authenticationReducer,
  user: fromUser.userReducer,
  client: fromClient.clientReducer,
  project: fromProject.projectReducer,
  job: fromJob.jobReducer,
  report: fromReport.reportReducer,
  analysis: fromAnalysis.analysisReducer,
  lead: fromLead.leadReducer,
  invoice: fromInvoice.invoiceReducer,
  currency: fromCurrencies.currencyReducer,
};
