import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './core/authentication/authentication.service';
import {Store} from '@ngrx/store';
import * as AppState from './store/app.reducer';
import * as AuthActions from './authentication/store/authentication.actions';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loggedIn = false;
  constructor(
    private authSrv: AuthenticationService,
    private store: Store<AppState.AppState>
  ) {}
  ngOnInit() {
    this.store.dispatch(new AuthActions.AutoLogin());
    this.store.select('authentication').subscribe(authData => {
      this.loggedIn = !!authData.user;
    });
  }
}
