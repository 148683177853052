import {Action} from '@ngrx/store';
import {ReportModel} from '../../../shared/models/report/report.model';

export const GET_START = '[Report] Get start';
export const GET_SUCCESS = '[Report] Get success';
export const CREATE_START = '[Report] Create start';
export const CREATE_SUCCESS = '[Report] Create success';
export const UPDATE_START = '[Report] Update start';
export const UPDATE_SUCCESS = '[Report] Update success';
export const DELETE_START = '[Report] Delete start';
export const DELETE_SUCCESS = '[Report] Delete success';
export const REPORT_FAIL = '[Report] Report fail';
export const CLEAR_ERROR = '[Report] Clear error';
export const CLEAR_SUCCESS = '[Report] Clear success';

export class GetStart implements Action {
  readonly type = GET_START;
  constructor(public payload: {filter: string}) {}
}
export class GetSuccess implements Action {
  readonly type = GET_SUCCESS;
  constructor(public payload: {reports: ReportModel[]}) {}
}
export class CreateStart implements Action {
  readonly type = CREATE_START;
  constructor(public payload: {report: ReportModel}) {}
}
export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;
  constructor(public payload: {report: ReportModel, message: string}) {}
}
export class UpdateStart implements Action {
  readonly type = UPDATE_START;
  constructor(public payload: {report: ReportModel, id: string}) {}
}
export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;
  constructor(public payload: {report: ReportModel, message: string}) {}
}
export class DeleteStart implements Action {
  readonly type = DELETE_START;
  constructor(public payload: {id: string}) {}
}
export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;
  constructor(public payload: {id: string, message: string}) {}
}
export class ReportFail implements Action {
  readonly type = REPORT_FAIL;
  constructor(public payload: {errorMessage: string, errors}) {}
}
export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ClearSuccess implements Action {
  readonly type = CLEAR_SUCCESS;
  constructor() {}
}

export type ReportActions =
  GetStart |
  GetSuccess |
  CreateStart |
  CreateSuccess |
  UpdateStart |
  UpdateSuccess |
  DeleteStart |
  DeleteSuccess |
  ReportFail |
  ClearError |
  ClearSuccess;
