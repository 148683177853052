import {Action} from '@ngrx/store';
import {InvoiceModel} from '../../../shared/models/invoice/invoice.model';

export const GET_START = '[Invoice] Get start';
export const GET_SUCCESS = '[Invoice] Get success';
export const CREATE_START = '[Invoice] Create  start';
export const CREATE_SUCCESS = '[Invoice] Create success';
export const DELETE_START = '[Invoice] Delete start';
export const DELETE_SUCCESS = '[Invoice] Delete success';
export const UPDATE_START = '[Invoice] Update start';
export const UPDATE_SUCCESS = '[Invoice] Update success';
export const INVOICE_FAIL = '[Invoice] Invoice fail';
export const CLEAR_ERROR = '[Invoice] Clear error';
export const CLEAR_SUCCESS = '[Invoice] Clear success';

export class GetStart implements Action {
  readonly type = GET_START;
  constructor(public payload: {filter: string}) {}
}
export class GetSuccess implements Action {
  readonly type = GET_SUCCESS;
  constructor(public payload: {invoices: InvoiceModel[]}) {}
}
export class CreateStart implements Action {
  readonly type = CREATE_START;
  constructor(public payload: {invoice: InvoiceModel}) {}
}
export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;
  constructor(public payload: {invoice: InvoiceModel, message: string}) {}
}
export class DeleteStart implements Action {
  readonly type = DELETE_START;
  constructor(public payload: {id: string}) {}
}
export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;
  constructor(public payload: {id: string, message: string}) {}
}
export class UpdateStart implements Action {
  readonly type = UPDATE_START;
  constructor(public payload: {id: string, invoice: InvoiceModel}) {}
}
export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;
  constructor(public payload: {invoice: InvoiceModel, message: string}) {}
}
export class InvoiceFail implements Action {
  readonly type = INVOICE_FAIL;
  constructor(public payload: {errorMessage: string, errors}) {}
}
export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ClearSuccess implements Action {
  readonly type = CLEAR_SUCCESS;
  constructor() {}
}

export type InvoiceActions =
  GetStart |
  GetSuccess |
  InvoiceFail |
  ClearError |
  ClearSuccess |
  CreateStart |
  CreateSuccess |
  DeleteStart |
  DeleteSuccess |
  UpdateStart |
  UpdateSuccess;
