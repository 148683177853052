import {Actions, Effect, ofType} from '@ngrx/effects';
import * as JobActions from '../store/job.actions';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {DefaultResponseModel} from '../../../shared/models/default-response.model';
import {JobModel} from '../../../shared/models/job/job.model';
import {JobSearchResponseModel} from '../../../shared/models/job/job-search-response.model';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {CurrencyModel} from '../../../shared/models/currency/currency.model';
import {CurrencyResponseModel} from '../../../shared/models/currency/currency-response.model';


const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error.message) {
    errorMessage = errorRes.errors.message;
  } else if (errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message;
    errors = errorRes.error.errors.errors;
  }
  return of(new JobActions.JobFail({errorMessage, errors}));
};
@Injectable()
export class JobEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router
  ) {}
  @Effect()
  getJobs = this.actions$.pipe(
    ofType(JobActions.GET_START),
    switchMap((jobData: JobActions.GetStart) => {
      return this.http.get<JobSearchResponseModel>(
        environment.baseApiUrl + 'jobs?filter=' + jobData.payload.filter
      ).pipe(
        map(resData => {
          return new JobActions.GetSuccess({jobs: resData.data as JobModel[]});
        }),
        catchError(resError => {
          return handleError(resError);
        })
      );
    })
  );
  @Effect()
  createJobs = this.actions$.pipe(
    ofType(JobActions.CREATE_START),
    switchMap((jobData: JobActions.CreateStart) => {
      return this.http.post<DefaultResponseModel>(
        environment.baseApiUrl + 'jobs', jobData.payload.job
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new JobActions.CreateSuccess({job: resData.success.data as JobModel, message: resData.success.message});
        }),
        catchError(resError => {
          return handleError(resError);
        })
      );
    })
  );
  @Effect()
  updateStart = this.actions$.pipe(
    ofType(JobActions.UPDATE_START),
    switchMap((jobData: JobActions.UpdateStart) => {
      return this.http.post<DefaultResponseModel>(
        environment.baseApiUrl + 'jobs/' + jobData.payload.id, jobData.payload.job
      ).pipe(
        map(resData => {
          return new JobActions.UpdateSuccess({job: resData.success.data as JobModel, message: resData.success.message});
        }),
        catchError(resError => {
          return handleError(resError);
        })
      );
    })
  )
  @Effect()
  deleteStart = this.actions$.pipe(
    ofType(JobActions.DELETE_START),
    switchMap((jobData: JobActions.DeleteStart) => {
      return this.http.delete<DefaultResponseModel>(
        environment.baseApiUrl + 'jobs/' + jobData.payload.id,
      {}
      ).pipe(
        map(resData => {
          return new JobActions.DeleteSuccess({id: resData.success.data, message: resData.success.message});
        }),
        catchError(resError => {
          return handleError(resError);
        })
      );
    })
  );
  @Effect({dispatch: false})
  jobSuccess = this.actions$.pipe(
    ofType(
      JobActions.CREATE_SUCCESS,
      JobActions.UPDATE_SUCCESS,
      JobActions.DELETE_SUCCESS
      ),
    tap(action => {
      this.router.navigate(['job/index']);
    })
  );
}
