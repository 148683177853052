export class User {
  username = '';
  password = '';
  constructor(
    public email: string = null,
    private _token?: string,
    private _tokenExpirationDate?: Date,
    private _username?: string,
    private _password?: string,
    public id?: string
  ) {
    this.username = _username;
    this.password = _password;
  }
  get token() {
    if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
      return null;
    }
    return this._token;
  }
}
