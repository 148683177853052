import * as JobActions from './job.actions';
import {JobModel} from '../../../shared/models/job/job.model';
import {BasicState} from '../../../shared/models/basic-state';

export interface State extends BasicState {
  jobs: JobModel[];
}

const initialState: State = {
  jobs: [],
  error: null,
  success: null,
  loading: null,
  hasLoaded: false
};

export function jobReducer(state: State = initialState, action: JobActions.JobActions ) {
  switch (action.type) {
    case JobActions.GET_START:
    case JobActions.CREATE_START:
    case JobActions.UPDATE_START:
    case JobActions.DELETE_START:
      return {
        ...state,
        error: null,
        success: null,
        loading: true
      }
    case JobActions.GET_SUCCESS:
      return {
        ...state,
        jobs: [...action.payload.jobs],
        error: null,
        loading: false,
        hasLoaded: true
      }
    case JobActions.CREATE_SUCCESS:
      return {
        ...state,
        jobs: [...state.jobs, action.payload.job],
        loading: false,
        success: action.payload.message
      }
    case JobActions.UPDATE_SUCCESS:
      const updatedJob = {
        ...state.jobs.find(job => job.id === action.payload.job.id),
        ...action.payload.job
      };
      const updateIndex = state.jobs.findIndex(job => job.id === action.payload.job.id);
      const updatedJobs = [...state.jobs];
      updatedJobs[updateIndex] = updatedJob;
      return {
        ...state,
        jobs: updatedJobs,
        loading: false,
        success: action.payload.message
      }
    case JobActions.DELETE_SUCCESS:
      const deleteIndex = state.jobs.findIndex(job => job.id === action.payload.id);
      state.jobs.splice(deleteIndex, 1);
      return {
        ...state,
        jobs: [...state.jobs],
        loading: false,
        success: action.payload.message
      }
    case JobActions.JOB_FAIL:
      return {
        ...state,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
        loading: false
      }
    case JobActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case JobActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      }
    default:
      return state;
  }
}
