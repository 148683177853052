import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {User} from '../../shared/models/user/user.model';
import {Store} from '@ngrx/store';
import * as AppState from '../../store/app.reducer';
import * as UserActions from '../../pages/user/store/user.actions';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl: string = environment.baseApiUrl;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };
  constructor(
    private http: HttpClient,
    private store: Store<AppState.AppState>
  ) { }
  getProfile() {
    this.store.select('user').subscribe(reportData => {
      // Check if the store has loaded data and if not start the retrieval of of the data form the backend.
      // If this conditional is not properly set it may result in recursive dispatching of the ReportActions.GetStart action
      if (!reportData.userProfile && !reportData.loading && !reportData.hasLoaded) {
        this.store.dispatch(new UserActions.ProfileStart());
      }
    });
  }
  getUsers() {
    return this.http.get<User[]>(this.baseUrl + 'users');
  }
  getUser(userId) {
    return this.http.get<User>(this.baseUrl + 'user?id=' + userId, this.httpOptions);
  }
  createUser(user: User): Observable<User> {
    return this.http.post<User>(this.baseUrl + 'user/create', user, this.httpOptions);
  }
  updateUser(user: User): Observable<any> {
    return this.http.put(this.baseUrl + 'user/update', user, this.httpOptions);
  }
  deleteUser(user: User): Observable<any> {
    return this.http.delete(this.baseUrl + 'user/delete?id=' + user.id, this.httpOptions);
  }
}
