import {ClientModel} from '../../../shared/models/client/client.model';
import * as ClientActions from './client.actions';
import {BasicState} from '../../../shared/models/basic-state';

export interface State extends BasicState {
  clients: ClientModel[];
}

const initialState: State = {
  clients: [],
  error: null,
  success: null,
  loading: false,
  hasLoaded: false
}

export function clientReducer(state = initialState, action: ClientActions.ClientActions) {
  switch (action.type) {
    case ClientActions.CREATE_START:
    case ClientActions.GET_START:
    case ClientActions.UPDATE_START:
    case ClientActions.DELETE_START:
    case ClientActions.UPLOAD_LOGO_START:
      return {
        ...state,
        error: null,
        success: null,
        loading: true
      }
    case ClientActions.CREATE_SUCCESS:
      return {
        ...state,
        clients: [...state.clients, action.payload.client],
        success: action.payload.message,
        loading: false
      }
    case ClientActions.GET_SUCCESS:
      return {
        ...state,
        clients: [...action.payload.clients],
        error: null,
        loading: false,
        hasLoaded: true
      }
    case ClientActions.UPDATE_SUCCESS:
      const updatedClient = {
        ...state.clients.find(client => client.id === action.payload.client.id),
        ...action.payload.client
      };
      const updateIndex = state.clients.findIndex(client => client.id === action.payload.client.id);
      const updatedClients = [...state.clients];
      updatedClients[updateIndex] = updatedClient;
      return {
        ...state,
        clients: updatedClients,
        error: null,
        loading: false
      }
    case ClientActions.DELETE_SUCCESS:
      const deleteIndex = state.clients.findIndex(client => client.id === action.payload.id);
      state.clients.splice(deleteIndex, 1);
      return {
        ...state,
        clients: [...state.clients],
        error: null,
        success: action.payload.message,
        loading: false
      }
    case ClientActions.UPLOAD_LOGO_SUCCESS:
      const clientIndex = state.clients.findIndex(client => client.id === action.payload.client.id);
      const clientsUpdated = [...state.clients];
      clientsUpdated[clientIndex] = action.payload.client;
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        clients: clientsUpdated
      }
    case ClientActions.CLIENT_FAIL:
      return{
        ...state,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
        loading: false
      }
    case ClientActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case ClientActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      }
    default:
      return state;
  }
}
