import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import * as InvoiceActions from '../../invoice/store/invoice.actions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {InvoiceModel} from '../../../shared/models/invoice/invoice.model';
import {InvoiceSearchResponseModel} from '../../../shared/models/invoice/invoice-search-response.model';
import {Router} from '@angular/router';
import {DefaultResponseModel} from '../../../shared/models/default-response.model';

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error.message) {
    errorMessage = errorRes.errors.message;
  } else if (errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message;
    errors = errorRes.error.errors.errors;
  }
  return of(new InvoiceActions.InvoiceFail({errorMessage, errors}));
};

@Injectable()
export class InvoiceEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router
  ) {}
  @Effect()
  getStart = this.actions$.pipe(
    ofType(InvoiceActions.GET_START),
    switchMap((invoiceData: InvoiceActions.GetStart) => {
      return this.http.get<InvoiceSearchResponseModel>(
        environment.baseApiUrl + 'invoices?filter=' + invoiceData.payload.filter
      ).pipe(
        map(resData => {
          return new InvoiceActions.GetSuccess({invoices: resData.data as InvoiceModel[]});
        }),
        catchError(resError => {
          return handleError(resError);
        })
      );
    })
  );
  @Effect()
  createStart = this.actions$.pipe(
    ofType(InvoiceActions.CREATE_START),
    switchMap((invoiceData: InvoiceActions.CreateStart) => {
      return this.http.post(
        environment.baseApiUrl + 'invoices', invoiceData.payload.invoice
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new InvoiceActions.CreateSuccess({
            invoice: resData.success.data as InvoiceModel,
            message: resData.success.message
          });
        }),
        catchError(resError => {
          return handleError(resError);
        })
      );
    })
  );
  @Effect()
  deleteStart = this.actions$.pipe(
    ofType(InvoiceActions.DELETE_START),
    switchMap((invoiceData: InvoiceActions.DeleteStart) => {
      return this.http.delete(
        environment.baseApiUrl + 'invoices/' + invoiceData.payload.id
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new InvoiceActions.DeleteSuccess({id: resData.success.data, message: resData.success.message});
        }),
        catchError(resError => {
          return handleError(resError);
        })
      );
    })
  );
  @Effect()
  updateStart = this.actions$.pipe(
    ofType(InvoiceActions.UPDATE_START),
    switchMap((invoiceData: InvoiceActions.UpdateStart) => {
      return this.http.post(
        environment.baseApiUrl + 'invoices/update/' + invoiceData.payload.id, invoiceData.payload.invoice
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new InvoiceActions.UpdateSuccess({invoice: resData.success.data as InvoiceModel, message: resData.success.message});
        }),
        catchError(resError => {
          return handleError(resError);
        })
      );
    })
  );
  @Effect({dispatch: false})
  invoiceSuccess = this.actions$.pipe(
    ofType(
      InvoiceActions.CREATE_SUCCESS,
      InvoiceActions.UPDATE_SUCCESS,
      InvoiceActions.DELETE_SUCCESS
    ),
    tap(() => {
      this.router.navigate(['invoice/index']);
    })
  );
}
