import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {AuthResponseData} from '../../shared/models/auth/auth-response.model';
import {User} from '../../shared/models/user/user.model';
import {environment} from '../../../environments/environment';
import * as AuthenticationActions from './authentication.actions';
import {DefaultResponseModel} from '../../shared/models/default-response.model';
import {CheckResetPasswordTokenModel} from '../../shared/models/auth/check-reset-password-token.model';

const handleAuth = (email: string, idToken: string, expiresIn: string) => {
  const loginExpirationDate = new Date(new Date().getTime() + +expiresIn * 1000);
  const user = new User(
    email,
    idToken,
    loginExpirationDate
  );
  localStorage.setItem('userData', JSON.stringify(user));
  return new AuthenticationActions.AuthenticateSuccess({
    email,
    token: idToken,
    expirationDate: loginExpirationDate
  });
};

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error && errorRes.error.message) {
    errorMessage = errorRes.error.message;
  } else if (errorRes.error && errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message ? errorRes.error.errors.message : 'An error occurred!';
    errors = errorRes.error.errors;
  } else if (errorRes.errors && errorRes.errors.message) {
    errorMessage = errorRes.errors.message ? errorRes.errors.message : 'An error occurred!';
    errors = errorRes.errors.errors;
  }
  return of(new AuthenticationActions.AuthenticateFail({errorMessage, errors}));
};

@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private authService: AuthenticationService
  ) {}
  @Effect()
  authRegister = this.actions$.pipe(
    ofType(AuthenticationActions.REGISTRATION_START),
    switchMap((registrationData: AuthenticationActions.RegistrationStart) => {
      return this.http.post<AuthResponseData>(
        environment.baseApiUrl + 'auth/register',
        {
          email: registrationData.payload.email,
          password: registrationData.payload.password,
          password_confirmation: registrationData.payload.passwordConfirmation,
          firstName: registrationData.payload.firstName,
          lastName: registrationData.payload.lastName
        },
      ).pipe(
        tap( resData => {
          this.authService.setLogoutTimer(+resData.expires_in * 1000);
        }),
        map(resData => {
          return handleAuth(
            registrationData.payload.email,
            resData.access_token,
            resData.expires_in
          );
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  authActions = this.actions$.pipe(
    ofType(AuthenticationActions.LOGIN_START),
    switchMap((authData: AuthenticationActions.LoginStart) => {
      return this.http.post<AuthResponseData>(
        environment.baseApiUrl + 'auth/login',
        {
          email: authData.payload.email,
          password: authData.payload.password
        }
      ).pipe(
        tap( resData => {
          this.authService.setLogoutTimer(+resData.expires_in * 1000);
        }),
        map(resData => {
          return handleAuth(
            authData.payload.email,
            resData.access_token,
            resData.expires_in
          );
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  authReset = this.actions$.pipe(
    ofType(AuthenticationActions.RESET_PASSWORD_EMAIL_START),
    switchMap((authData: AuthenticationActions.ResetPasswordEmailStart) => {
      return this.http.post<DefaultResponseModel>(
        environment.baseApiUrl + 'auth/send-reset-password-email',
        {
          email: authData.payload.email,
          url: location.origin + '/auth/reset-password-confirm'
        }
      ).pipe(
        map(resData => {
          return new AuthenticationActions.ResetPasswordEmailSuccess({message: resData.success.message});
        }),
        catchError(errorRes => {
          return handleError(errorRes.error);
        })
      );
    })
  );
  @Effect()
  checkResetPasswordToken = this.actions$.pipe(
    ofType(AuthenticationActions.CHECK_TOKEN_START),
    switchMap((authData: AuthenticationActions.CheckTokenStart) => {
      return this.http.post<CheckResetPasswordTokenModel>(
        environment.baseApiUrl + 'auth/check-password-reset-token',
        {
          email: authData.payload.email,
          token: authData.payload.token
        }
      ).pipe(
        map(resData => {
          return new AuthenticationActions.CheckTokenSuccess({
            message: resData.success.message,
            isTokenValid: resData.success.data.isValidToken
          });
        }),
        catchError((errorRes: CheckResetPasswordTokenModel) => {
          return of(new AuthenticationActions.CheckTokenFail({
            message: errorRes.error.errors.message,
            isTokenValid: errorRes.error.errors.errors.isValidToken
          }));
        })
      );
    })
  );
  @Effect()
  resetPasswordStart = this.actions$.pipe(
    ofType(AuthenticationActions.RESET_PASSWORD_START),
    switchMap((authData: AuthenticationActions.ResetPasswordStart) => {
      return this.http.post<DefaultResponseModel>(
        environment.baseApiUrl + 'auth/reset-password',
        {
          email: authData.payload.email,
          password: authData.payload.password,
          token: authData.payload.token
        }
      ).pipe(
        map(resData => {
          return new AuthenticationActions.ResetPasswordSuccess({message: resData.success.message});
        }),
        catchError(errorRes => {
          return handleError(errorRes.error);
        })
      );
    })
  );
  @Effect({dispatch: false})
  authRedirect = this.actions$.pipe(
    ofType(AuthenticationActions.AUTHENTICATE_SUCCESS),
    tap(() => {
      this.router.navigate(['/home']);
    })
  );
  @Effect()
  autoLogin = this.actions$.pipe(
    ofType(AuthenticationActions.AUTO_LOGIN),
    map(() => {
      const userData: {
        email: string;
        id: string;
        _token: string;
        _tokenExpirationDate: string;
      } = JSON.parse(localStorage.getItem('userData'));
      if (!userData) {
        return { type: 'NULL'};
      }
      const loadedUser = new User(
        userData.email,
        userData._token,
        new Date(userData._tokenExpirationDate)
      );
      if (loadedUser.token) {
        const expirationDuration = new Date(userData._tokenExpirationDate).getTime() - new Date().getTime();
        this.authService.setLogoutTimer(expirationDuration);
        return new AuthenticationActions.AuthenticateSuccess({
          email: loadedUser.email,
          token: loadedUser.token,
          expirationDate: new Date(userData._tokenExpirationDate)
        });
      }
      return { type: 'NULL'};
    })
  );
  @Effect({dispatch: false})
  authLogout = this.actions$.pipe(
    ofType(
      AuthenticationActions.LOGOUT
    ),
    tap(() => {
      this.authService.clearLogoutTimer();
      localStorage.removeItem('userData');
      this.router.navigate(['/auth']);
    })
  );
  @Effect({dispatch: false})
  authResetPasswordSuccess = this.actions$.pipe(
    ofType(
      AuthenticationActions.RESET_PASSWORD_SUCCESS
    ),
    tap(() => {
      this.router.navigate(['/auth']);
    })
  );
}
