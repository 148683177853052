import {Action} from '@ngrx/store';
import {JobModel} from '../../../shared/models/job/job.model';

export const GET_START = '[Job] Get job start';
export const GET_SUCCESS = '[Job] Get job success';
export const CREATE_START = '[Job] Create job start';
export const CREATE_SUCCESS = '[Job] Create job success';
export const UPDATE_START = '[Job] Update job start';
export const UPDATE_SUCCESS = '[Job] Update job success';
export const DELETE_START = '[Job] Delete job start';
export const DELETE_SUCCESS = '[Job] Delete job success';
export const JOB_FAIL = '[Job] Job fail';
export const CLEAR_ERROR = '[Job] Clear error';
export const CLEAR_SUCCESS = '[Job] Clear success';

export class GetStart implements Action {
  readonly type = GET_START;
  constructor(public payload: {filter: string}) {}
}
export class GetSuccess implements Action {
  readonly type = GET_SUCCESS;
  constructor(public payload: {jobs: JobModel[]}) {}
}
export class CreateStart implements Action {
  readonly type = CREATE_START;
  constructor(public payload: {job: JobModel}) {}
}
export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;
  constructor(public payload: {job: JobModel, message: string}) {}
}
export class UpdateStart implements Action {
  readonly type = UPDATE_START;
  constructor(public payload: {job: JobModel, id: string}) {}
}
export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;
  constructor(public payload: {job: JobModel, message: string}) {}
}
export  class DeleteStart implements Action {
  readonly  type = DELETE_START;
  constructor(public payload: {id: string}) {}
}
export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;
  constructor(public payload: {id: string, message: string}) {}
}
export class JobFail implements Action {
  readonly type = JOB_FAIL;
  constructor(public payload: {errorMessage: string, errors}) {}
}
export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ClearSuccess implements Action {
  readonly type = CLEAR_SUCCESS;
  constructor() {}
}
export type JobActions =
  GetStart |
  GetSuccess |
  CreateStart |
  CreateSuccess |
  DeleteStart |
  DeleteSuccess |
  UpdateStart |
  UpdateSuccess |
  JobFail |
  ClearError |
  ClearSuccess;
