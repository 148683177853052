import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { AuthenticationInterceptorService } from './core/authentication/authentication-interceptor.service';
import { HomeModule } from './pages/home/home.module';
import {NbMenuModule, NbSidebarModule, NbThemeModule} from '@nebular/theme';
// Redux Store
import {StoreModule} from '@ngrx/store';
import * as fromApp from './store/app.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AuthenticationEffects} from './authentication/store/authentication.effects';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import {ClientEffects} from './pages/client/store/client.effects';
import {ProjectEffects} from './pages/project/store/project.effects';
import {JobEffects} from './pages/job/store/job.effects';
import {ReportEffects} from './pages/report/store/report.effects';
import {AnalysisEffects} from './pages/analysis/store/analysis.effects';
import {LeadEffects} from './pages/lead/store/lead.effects';
import {UserEffects} from './pages/user/store/user.effects';
import {InvoiceEffects} from './pages/invoice/store/invoice.effects';
import {CurrencyEffects} from './pages/currency/store/currency.effects';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot(fromApp.appReducer),
    EffectsModule.forRoot([
      AuthenticationEffects,
      ClientEffects,
      ProjectEffects,
      JobEffects,
      ReportEffects,
      AnalysisEffects,
      LeadEffects,
      UserEffects,
      InvoiceEffects,
      CurrencyEffects
    ]),
    SharedModule,
    HomeModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
