import * as InvoiceActions from './invoice.actions';
import {BasicState} from '../../../shared/models/basic-state';
import {InvoiceModel} from '../../../shared/models/invoice/invoice.model';

export interface State extends BasicState {
  invoices: InvoiceModel[];
}

const initialState: State = {
  invoices: [],
  error: null,
  success: null,
  loading: null,
  hasLoaded: false
};

export function invoiceReducer(state: State = initialState, action: InvoiceActions.InvoiceActions) {
  switch (action.type) {
    case InvoiceActions.GET_START:
    case InvoiceActions.CREATE_START:
    case InvoiceActions.UPDATE_START:
    case InvoiceActions.DELETE_START:
      return {
        ...state,
        loading: true,
        error: null,
        success: null
      };
    case InvoiceActions.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: [...action.payload.invoices],
        hasLoaded: true
      };
    case InvoiceActions.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        invoices: [...state.invoices, action.payload.invoice]
      }
    case InvoiceActions.UPDATE_SUCCESS:
      const updatedInvoice = {
        ...state.invoices.find(job => job.id === action.payload.invoice.id),
        ...action.payload.invoice
      };
      const updateIndex = state.invoices.findIndex(invoice => invoice.id === action.payload.invoice.id);
      const updatedInvoices = [...state.invoices];
      updatedInvoices[updateIndex] = updatedInvoice;
      return {
        ...state,
        invoices: updatedInvoices,
        loading: false,
        success: action.payload.message
      }
    case InvoiceActions.DELETE_SUCCESS:
      const deleteIndex = state.invoices.findIndex(invoice => invoice.id === action.payload.id);
      state.invoices.splice(deleteIndex, 1);
      return {
        ...state,
        jobs: [...state.invoices],
        loading: false,
        success: action.payload.message
      }
    case InvoiceActions.INVOICE_FAIL:
      return {
        ...state,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
        loading: false,
        hasLoaded: true
      };
    case InvoiceActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      };
    case InvoiceActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
}
