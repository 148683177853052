import {Action} from '@ngrx/store';
import {UserProfile} from '../../../shared/models/user/user-profile.model';

export const PROFILE_START = '[User] Profile get';
export const PROFILE_SUCCESS = '[User] Profile success';
export const USER_FAIL = '[User] Fail';
export const CLEAR_ERROR = '[User] Clear error';
export const CLEAR_SUCCESS = '[User] Clear success';


export class ProfileStart implements Action {
  readonly  type = PROFILE_START;
}
export class ProfileSuccess implements Action {
  readonly  type = PROFILE_SUCCESS;
  constructor(public payload: { user: UserProfile }) {}
}
export class UserFail implements Action {
  readonly type = USER_FAIL;
  constructor(public payload: {errorMessage: string, errors}) {}
}
export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ClearSuccess implements Action {
  readonly type = CLEAR_SUCCESS;
  constructor() {}
}

export type UserActions =
  ProfileStart |
  ProfileSuccess |
  UserFail |
  ClearError |
  ClearSuccess;
