import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {

  transform(value: number): string {
    const  temp = value;
    const hours = Math.floor((temp / 60));
    const minutes = temp - (hours * 60);
    return hours + 'h:' + minutes + 'm';
  }

}
