import {Action} from '@ngrx/store';
import {ReportModel} from '../../../shared/models/report/report.model';

export const FILTER_START = '[Analysis] Filter start';
export const FILTER_SUCCESS = '[Analysis] Filter success';
export const FILTER_FAIL = '[Analysis] Filter fail';

export class FilterStart implements Action {
  readonly type = FILTER_START;
  constructor(public payload: {filter: string}) {}
}
export class FilterSuccess implements Action {
  readonly type = FILTER_SUCCESS;
  constructor(public payload: {reports: ReportModel[], sumTimeInMinutes: number}) {}
}
export class FilterFail implements  Action {
  readonly type = FILTER_FAIL;
  constructor(public payload: {errorMessage: string, errors}) {}
}

export type AnalysisActions = FilterStart | FilterSuccess | FilterFail;
