import {Action} from '@ngrx/store';

import {ClientModel} from '../../../shared/models/client/client.model';

export const CREATE_START = '[Client] Create start';
export const CREATE_SUCCESS = '[Client] Create success';
export const GET_START = '[Client] Get start';
export const GET_SUCCESS = '[Client] Get success';
export const UPDATE_START = '[Client] Update start';
export const UPDATE_SUCCESS = '[Client] Update success';
export const DELETE_START = '[Client] Delete start';
export const DELETE_SUCCESS = '[Client] Delete success';
export const UPLOAD_LOGO_START = '[Client] Upload logo start';
export const UPLOAD_LOGO_SUCCESS = '[Client] Upload logo success';
export const CLIENT_FAIL = '[Client] Client fail';
export const CLEAR_ERROR = '[Client] Clear error';
export const CLEAR_SUCCESS = '[Client] Clear success';

export class CreateStart implements Action {
  readonly type = CREATE_START;
  constructor(public payload: {client: ClientModel}) {}
}
export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;
  constructor(public payload: {client: ClientModel, message: string}) {}
}
export class GetStart implements Action {
  readonly type = GET_START;
  constructor(public payload: {filter: string}) {}
}
export class GetSuccess implements Action {
  readonly type = GET_SUCCESS;
  constructor(public payload: {clients: ClientModel[]}) {}
}
export class UpdateStart implements Action {
  readonly type = UPDATE_START;
  constructor(public payload: {client: ClientModel, id: string}) {}
}
export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;
  constructor(public payload: {client: ClientModel}) {}
}
export class DeleteStart implements Action {
  readonly type = DELETE_START;
  constructor(public payload: {id: string}) {}
}
export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;
  constructor(public payload: {message: string, id: string}) {}
}
export class ClientFail implements Action {
  readonly type = CLIENT_FAIL;
  constructor(public payload: {errorMessage: string, errors}) {}
}
export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ClearSuccess implements Action {
  readonly type = CLEAR_SUCCESS;
  constructor() {}
}
export class UploadLogoStart implements Action {
  readonly type = UPLOAD_LOGO_START;
  constructor(public payload: {logo: any, id: string}) {}
}
export class UploadLogoSuccess implements Action {
  readonly type = UPLOAD_LOGO_SUCCESS;
  constructor(public payload: {client: ClientModel, message: string}) {}
}

export type ClientActions =
  CreateStart |
  CreateSuccess |
  GetStart |
  GetSuccess |
  UpdateStart |
  UpdateSuccess |
  DeleteStart |
  DeleteSuccess |
  ClientFail |
  ClearError |
  ClearSuccess |
  UploadLogoStart |
  UploadLogoSuccess;
