import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import * as ProjectActions from './project.actions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {of} from 'rxjs';
import {ProjectSearchResponseModel} from '../../../shared/models/project/project-search-response.model';
import {ProjectModel} from '../../../shared/models/project/project.model';
import {DefaultResponseModel} from '../../../shared/models/default-response.model';

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error.message) {
    errorMessage = errorRes.errors.message;
  } else if (errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message;
    errors = errorRes.error.errors.errors;
  }
  return of(new ProjectActions.ProjectFail({errorMessage, errors}));
};

@Injectable()
export class ProjectEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private http: HttpClient
  ) {}
  @Effect()
  projectGetStart = this.actions$.pipe(
    ofType(ProjectActions.GET_START),
    switchMap((projectData: ProjectActions.GetStart) => {
      return this.http.get<ProjectSearchResponseModel>(
        environment.baseApiUrl + 'projects?filter=' + projectData.payload.filter,
        {}
      ).pipe(
        map(resData => {
          return new ProjectActions.GetSuccess({projects: resData.data});
        }),
        catchError((errorRes) => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  projectCreateStart = this.actions$.pipe(
    ofType(ProjectActions.CREATE_START),
    switchMap((projectData: ProjectActions.CreateStart) => {
      return this.http.post<DefaultResponseModel>(
        environment.baseApiUrl + 'projects',
        {
          name: projectData.payload.project.name
        }
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new ProjectActions.CreateSuccess({project: resData.success.data as ProjectModel});
        }),
        catchError((errorRes) => {
          return handleError(errorRes);
        })
      );
    })
  )
  @Effect()
  projectUpdateStart = this.actions$.pipe(
    ofType(ProjectActions.UPDATE_START),
    switchMap((projectData: ProjectActions.UpdateStart) => {
      return this.http.post<DefaultResponseModel>(
        environment.baseApiUrl + 'projects/' + projectData.payload.id,
        {
          name: projectData.payload.project.name
        }
      ).pipe(
        map((updateResponse: DefaultResponseModel) => {
          return new ProjectActions.UpdateSuccess({project: updateResponse.success.data as ProjectModel});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  )
  @Effect()
  projectDeleteStart = this.actions$.pipe(
    ofType(ProjectActions.DELETE_START),
    switchMap((projectData: ProjectActions.DeleteStart) => {
      return this.http.delete(
        environment.baseApiUrl + 'projects/' + projectData.payload.id,
        {}
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new ProjectActions.DeleteSuccess({message: resData.success.message, id: resData.success.data.id});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  )
  @Effect({dispatch: false})
  projectSuccess = this.actions$.pipe(
    ofType(
      ProjectActions.CREATE_SUCCESS,
      ProjectActions.UPDATE_SUCCESS
    ),
    tap(action => {
      this.router.navigate(['/project/index']);
    })
  );
}
