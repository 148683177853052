import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import * as CurrencyActions from './currency.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {CurrencyResponseModel} from '../../../shared/models/currency/currency-response.model';
import {CurrencyModel} from '../../../shared/models/currency/currency.model';
import {of} from 'rxjs';

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error.message) {
    errorMessage = errorRes.errors.message;
  } else if (errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message;
    errors = errorRes.error.errors.errors;
  }
  return of(new CurrencyActions.CurrencyFail({errorMessage, errors}));
};

@Injectable()
export class CurrencyEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient
  ) {
  }

  @Effect()
  getStart = this.actions$.pipe(
    ofType(CurrencyActions.GET_START),
    switchMap(() => {
      return this.http.get(
        environment.baseApiUrl + 'currencies'
      ).pipe(
        map((resData: CurrencyResponseModel) => {
          return new CurrencyActions.GetSuccess({currencies: resData.data as CurrencyModel[]});
        }),
        catchError(resError => {
          return handleError(resError);
        })
      );
    })
  );
}
