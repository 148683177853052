import {Action} from '@ngrx/store';
import {ProjectModel} from '../../../shared/models/project/project.model';

export const GET_START = '[Project] Get start';
export const GET_SUCCESS = '[Project] Get success';
export const CREATE_START = '[Project] Create start';
export const CREATE_SUCCESS = '[Project] Create success';
export const UPDATE_START = '[Project] Update start';
export const UPDATE_SUCCESS = '[Project] Update success';
export const DELETE_START = '[Project] Delete start';
export const DELETE_SUCCESS = '[Project] Delete success';
export const PROJECT_FAIL = '[Project] Project fail';
export const CLEAR_ERROR = '[Project] Clear error';
export const CLEAR_SUCCESS = '[Project] Clear success';

export class GetStart implements Action {
  readonly type = GET_START;
  constructor(public payload: {filter: string}) {}
}
export class GetSuccess implements Action {
  readonly type = GET_SUCCESS;
  constructor(public payload: {projects: ProjectModel[]}) {}
}
export class CreateStart implements Action {
  readonly type = CREATE_START;
  constructor(public payload: {project: ProjectModel}) {}
}
export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;
  constructor(public payload: {project: ProjectModel}) {}
}
export class UpdateStart implements Action {
  readonly type = UPDATE_START;
  constructor(public payload: {project: ProjectModel, id: string}) {}
}
export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;
  constructor(public payload: {project: ProjectModel}) {}
}
export class DeleteStart implements Action {
  readonly type = DELETE_START;
  constructor(public payload: { id: string}) {}
}
export class DeleteSuccess implements Action {
  readonly type = DELETE_SUCCESS;
  constructor(public payload: {message: string, id: string}) {}
}
export class ProjectFail {
  readonly type = PROJECT_FAIL;
  constructor(public payload: {errorMessage: string, errors}) {}
}
export class ClearError implements Action {
  readonly type = CLEAR_ERROR;
  constructor() {}
}
export class ClearSuccess implements Action {
  readonly type = CLEAR_SUCCESS;
  constructor() {}
}

export type ProjectActions =
  GetStart |
  GetSuccess |
  CreateStart |
  CreateSuccess |
  UpdateStart |
  UpdateSuccess |
  DeleteStart |
  DeleteSuccess |
  ProjectFail |
  ClearError |
  ClearSuccess;
