import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService} from '../authentication/authentication.service';
import {Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {map} from 'rxjs/operators';
import * as fromApp from '../../store/app.reducer';
import * as AuthenticationActions from '../../authentication/store/authentication.actions';
import {UserProfile} from '../../shared/models/user/user-profile.model';
import {NbMenuService} from '@nebular/theme';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  items = [
    { title: 'Profile' },
    { title: 'Logout' },
  ];
  fullName: string = null;
  isLoggedIn = false;
  userSub: Subscription;
  profile: UserProfile;
  constructor(
    private authSrv: AuthenticationService,
    private store: Store<fromApp.AppState>,
    private nbMenuService: NbMenuService,
    private router: Router
  ) { }

  ngOnInit() {
    this.store.select('user').subscribe(userState => {
      if (!!userState.userProfile) {
        this.profile = userState.userProfile;
        this.fullName = this.profile.firstName + ' ' + this.profile.lastName;
      }
    });
    this.userSub = this.store.select('authentication')
      .pipe(
        map(authState => {
          return authState.user;
        })
      )
      .subscribe(user => {
        this.isLoggedIn = !!user;
      });
    this.nbMenuService.onItemClick()
      .pipe(
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (title === 'Profile') {
          this.router.navigate(['user/profile']);
        }
        if (title === 'Logout') {
          this.onLogout();
        }
      });
  }
  onLogout() {
    this.authSrv.clearError();
    this.store.dispatch(
      new AuthenticationActions.Logout()
    );
  }
  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
