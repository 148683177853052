import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {HttpClient} from '@angular/common/http';
import {of} from 'rxjs';
import * as UserActions from './user.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {MeResponse} from '../../../shared/models/auth/me-reponse';
import {environment} from '../../../../environments/environment';
import {UserProfile} from '../../../shared/models/user/user-profile.model';

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error.message) {
    errorMessage = errorRes.errors.message;
  } else if (errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message;
    errors = errorRes.error.errors.errors;
  }
  return of(new UserActions.UserFail({errorMessage, errors}));
};

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient
  ) {}
  @Effect()
  getProfile = this.actions$.pipe(
    ofType(UserActions.PROFILE_START),
    switchMap(() => {
      return this.http.post(
        environment.baseApiUrl + 'auth/me', {}
      ).pipe(
        map(resData => {
          return new UserActions.ProfileSuccess({user: resData as UserProfile});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
}
