import * as UserActions from './user.actions';
import {BasicState} from '../../../shared/models/basic-state';
import {UserProfile} from '../../../shared/models/user/user-profile.model';

export interface State extends BasicState {
  userProfile: UserProfile;
}

const initialState: State = {
  userProfile: null,
  loading: false,
  error: null,
  success: null,
  hasLoaded: null
};

export function userReducer(state: State = initialState, action: UserActions.UserActions) {
  switch (action.type) {
    case UserActions.PROFILE_START:
      return {
        ...state,
        loading: true,
        error: null,
        success: null
      }
    case UserActions.PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload.user,
        loading: false,
        hasLoaded: true
      }
    case UserActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case UserActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      }
    default:
      return state;
  }
}
