import * as AnalysisActions from './analysis.actions';
import {ReportModel} from '../../../shared/models/report/report.model';
import {BasicState} from '../../../shared/models/basic-state';

export interface State extends BasicState {
  reports: ReportModel[];
  projectId: string;
  clientId: string;
  jobId: string;
  sumTimeInMinutes: number;
}

const initialState: State = {
  reports: [],
  projectId: null,
  clientId: null,
  jobId: null,
  loading: false,
  error: null,
  success: null,
  sumTimeInMinutes: null
};

export function analysisReducer(state: State = initialState, action: AnalysisActions.AnalysisActions) {
  switch (action.type) {
    case AnalysisActions.FILTER_START:
      return {
        ...state,
        loading: true,
        error: null
      }
    case AnalysisActions.FILTER_SUCCESS:
      return {
        ...state,
        reports: [...action.payload.reports],
        sumTimeInMinutes: action.payload.sumTimeInMinutes,
        loading: false
      }
    case AnalysisActions.FILTER_FAIL:
      return{
        ...state,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
        loading: false
      }
    default:
      return state;
  }
}
