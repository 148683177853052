import {Actions, Effect, ofType} from '@ngrx/effects';
import * as LeadActions from './lead.actions';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {LeadSearchResponseModel} from '../../../shared/models/lead/lead-search-response.model';
import {LeadModel} from '../../../shared/models/lead/lead.model';
import {Router} from "@angular/router";
import {DefaultResponseModel} from "../../../shared/models/default-response.model";

const handleError = (errorRes: any) => {
  let errorMessage = 'An error occurred!';
  let errors = null;
  if (errorRes.error.message) {
    errorMessage = errorRes.error.message;
  } else if (errorRes.error.errors) {
    errorMessage = errorRes.error.errors.message;
    errors = errorRes.error.errors.errors;
  }
  return of(new LeadActions.LeadFail({errorMessage, errors}));
};
@Injectable()
export class LeadEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router
  ) {}
  @Effect()
  getStart = this.actions$.pipe(
    ofType(LeadActions.GET_START),
    switchMap((leadData: LeadActions.GetStart) => {
      return this.http.get(
        environment.baseApiUrl + 'leads?filter=' + leadData.payload.filter
      ).pipe(
        map((resData: LeadSearchResponseModel) => {
          return new LeadActions.GetSuccess({leads: resData.data as LeadModel[]});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  createStart = this.actions$.pipe(
    ofType(LeadActions.CREATE_START),
    switchMap((leadData: LeadActions.CreateStart) => {
      return this.http.post(
        environment.baseApiUrl + 'leads', leadData.payload.lead
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new LeadActions.CreateSuccess({lead: resData.success.data as LeadModel, message: resData.success.message});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  updateCreate = this.actions$.pipe(
    ofType(LeadActions.UPDATE_START),
    switchMap((leadData: LeadActions.UpdateStart) => {
      return this.http.post(
        environment.baseApiUrl + 'leads/' + leadData.payload.id, leadData.payload.lead
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new LeadActions.UpdateSuccess({lead: resData.success.data as LeadModel, message: resData.success.message});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect()
  deleteStart = this.actions$.pipe(
    ofType(LeadActions.DELETE_START),
    switchMap((leadData: LeadActions.DeleteStart) => {
      return this.http.delete(
        environment.baseApiUrl + 'leads/' + leadData.payload.id
      ).pipe(
        map((resData: DefaultResponseModel) => {
          return new LeadActions.DeleteSuccess({id: resData.success.data , message: resData.success.message});
        }),
        catchError(errorRes => {
          return handleError(errorRes);
        })
      );
    })
  );
  @Effect({dispatch: false})
  leadSuccess = this.actions$.pipe(
    ofType(
      LeadActions.CREATE_SUCCESS,
      LeadActions.UPDATE_SUCCESS
    ),
    tap(action => {
      this.router.navigate(['lead/index']);
    })
  );
}
