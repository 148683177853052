import {User} from '../../shared/models/user/user.model';
import * as AuthenticationActions from './authentication.actions';
import {UserProfile} from '../../shared/models/user/user-profile.model';
import {BasicState} from '../../shared/models/basic-state';

export interface State extends BasicState {
  user: User;
  userProfile: UserProfile;
  authenticationError: string;
  resetPassword: {
    isValidToken: boolean;
  };
}

const initialState: State = {
  user: null,
  userProfile: null,
  authenticationError: null,
  error: null,
  success: null,
  loading: false,
  hasLoaded: false,
  resetPassword: {
    isValidToken: false
  }
};

export function authenticationReducer(state = initialState, action: AuthenticationActions.AuthenticationActions) {
  switch (action.type) {
    case AuthenticationActions.REGISTRATION_START:
    case AuthenticationActions.LOGIN_START:
    case AuthenticationActions.RESET_PASSWORD_EMAIL_START:
    case AuthenticationActions.CHECK_TOKEN_START:
    case AuthenticationActions.RESET_PASSWORD_START:
      return {
        ...state,
        error: null,
        success: null,
        loading: true
      };
    case AuthenticationActions.AUTHENTICATE_SUCCESS:
      const newUser = new User(
        action.payload.email,
        action.payload.token,
        action.payload.expirationDate
      );
      return {
        ...state,
        user: newUser,
        authenticationError: null,
        loading: false
      };
    case AuthenticationActions.RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message
      }
    case AuthenticationActions.CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message,
        resetPassword: {
          isValidToken: action.payload.isTokenValid
        }
      }
    case AuthenticationActions.CHECK_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        error: {
          errorMessage: action.payload.message,
          errors: null
        },
        resetPassword: {
          isValidToken: action.payload.isTokenValid
        }
      }
    case AuthenticationActions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload.message
      }
    case AuthenticationActions.AUTHENTICATE_FAIL:
      return {
        ...state,
        user: null,
        error: {
          errorMessage: action.payload.errorMessage,
          errors: action.payload.errors
        },
        loading: false
      };
    case AuthenticationActions.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case AuthenticationActions.CLEAR_SUCCESS:
      return {
        ...state,
        success: null
      }
    case AuthenticationActions.LOGOUT:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}
