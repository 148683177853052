import {Component, Injectable, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent {
  message: string = null;
  constructor(
    private reference: NbDialogRef<ConfirmDialogComponent>
  ) { }

  cancel() {
    this.reference.close(false);
  }
  confirm() {
    this.reference.close(true);
  }
}
